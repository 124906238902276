import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Disclosure, Menu, Transition} from '@headlessui/react'
import {Bars3Icon, BellIcon, XMarkIcon, ListBulletIcon, HomeIcon} from '@heroicons/react/24/outline'

import {useLoaderData, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {
	addEventAttribute,
	adminEventCard,
	audienceButtons, MetaTags,
	setUniqueEventAttribute,
	smallEventCard,
	smallSchemaArtistCard,
	toastSuccess
} from "../../components/common/utilities";
import {ChevronDownIcon, MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {ArrowLongLeftIcon, ArrowLongRightIcon, ChevronDoubleLeftIcon} from "@heroicons/react/16/solid";
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import {Footer, Header} from "../../components/common/headerAndFooter";

function classNames(...classes: any[]) {
	return classes.filter(Boolean).join(' ')
}

export default function Whatarewedoingforthemedia() {
	const navigate = useNavigate();
	const logout = () => {
		window.location.href = '/logout';
	}



	type SchemaArtist = {
		[key: string]: any; // ?? variable key
		name: string;
	};
const [artistData, setArtistData] = useState<SchemaArtist>();

	const stopBouncing = () => {
		const el = document.getElementById("promo-icon");
		if (el) {
			el.classList.remove("animate-bounce");
		}
	}

	var date = new Date();
	useEffect(() => {
		setTimeout(() => stopBouncing(), 3500)
		//getPhotos();

	}, []);

	const pages = [

		{ name: 'Fyrir Fjölmiðla', href: '/forthemedia', current: true },
		{ name: 'Menningarumfjöllun', href: '/documentation/mediaiceland', current: false },
		{ name: 'Auglýsingar', href: '/documentation/admarketiceland', current: false },
	]

	return (<>
		{/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}

		<MetaTags
			title="Hvað getum við gert fyrir fjölmiðla?"
			description={'Snúum vörn í sókn gagnvart auglýsingum á samfélagsmiðlum og sækjum tekjurnar heim. Fáum fleiri síðuflettingar og veitum lesendum betri þjónustu.'}
			image="https://promogogo.com/cdn-cgi/image/fit=contain,width=400/https://media.promogogo.com/ymsirvidburdir/2024-08-26/ymsirvidburdir-3dd43861-6d71-4d23-8a76-2e20c565fcb0-formedia.png"
		/>
		<Header/>

		<div
			className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">

			<div
				className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
				<div
					className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">

					<div className="lg:pr-4">
						<div className="lg:max-w-lg">

							<p className="text-base font-semibold leading-7 text-indigo-600">Hvað getum við gert fyrir
								fjölmiðla?</p>
							<h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meiri
								tekjur - meira efni</h1>
							<p className="mt-6 text-xl leading-8 text-gray-700">
								Snúum vörn í sókn gagnvart auglýsingum á samfélagsmiðlum og sækjum tekjurnar heim. Fáum
								fleiri síðuflettingar og veitum lesendum betri þjónustu.
							</p>
						</div>
					</div>
				</div>
				<div
					className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
					<img
						className="max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 "
						src="https://promogogo.com/cdn-cgi/image/fit=contain,width=400/https://media.promogogo.com/ymsirvidburdir/2024-08-26/ymsirvidburdir-3dd43861-6d71-4d23-8a76-2e20c565fcb0-formedia.png"
						alt=""
					/>
				</div>
				<div
					className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
					<div className="lg:pr-4">
						<div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
							<p>
								Með því að safna saman upplýsingum um alla viðburði á öllum sviðum um land allt
								getum við boðið fjölmiðlum og viðburðahöldurum að:
							</p>

							<ul role="list" className="mt-8 space-y-8 text-gray-600">
								<li className="flex gap-x-3">
									<ListBulletIcon className="mt-1 h-5 w-5 flex-none text-indigo-600"
													aria-hidden="true"/>
									<span>
                    <strong className="font-semibold text-gray-900">"boosta" viðburðum á staðarmiðlum.</strong> rétt eins og á Facebook eða Instagram. Jafn
										auðvelt. Jafn áhrifaríkt.
                  </span>
								</li>
								<li className="flex gap-x-3">
									<ListBulletIcon className="mt-1 h-5 w-5 flex-none text-indigo-600"
													aria-hidden="true"/>
									<span>
                    <strong className="font-semibold text-gray-900">auðvelda alla umfjöllun um listir
										og dægradvöl.</strong> Allar upplýsingar, miðaverð, lýsingar.
                  </span>
								</li>

								<li className="flex gap-x-3">
									<ListBulletIcon className="mt-1 h-5 w-5 flex-none text-indigo-600"
													aria-hidden="true"/>
									<span>
                    <strong className="font-semibold text-gray-900">birta valda eða flokkaða viðburði</strong> á vef eða í appi til að bæta þjónustu, fjölga síðuflettingum og gleðja lesendur. Svo mæta fleiri á viðburðina í kjölfarið sem treystir stoðir samfélagsins.
                  </span>
								</li>
							</ul>
							<p className="mt-8">
								Hið opinbera og aðrir sem setja umtalsvert kynningarfé beint til Facebook geta
								nú loksins náð sömu áhrifum innanlands. Það er samfélagsleg ábyrgð sem styrkir íslenska
								fjölmiðlun.
							</p>

							<div className="mt-10 flex items-center gap-x-6">
								<a
									href="/forthemedia"
									className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>
									Miðlar
								</a>

								<a
									href="/media"
									className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>
									Umfjöllun
								</a>
								<a
									href="/admarket"
									className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>
									Tekjur
								</a>

							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>


	</>)
}
