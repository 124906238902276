import React, {Fragment, useEffect, useRef, useState} from 'react'
import axios, * as others from 'axios';
import {Link, useLoaderData, useLocation, useParams} from "react-router-dom";
import {
    addEventAttribute,
    adminVenueCard,
    audienceButtons,
    changeAttribute,
    getAttribute,
    getImageWithPrefix,
    getLocalDate,
    hasAttribute,
    manageVenueData,
    MetaTags, overlayCard,
    setAttribute,
    setRoomAttribute,
    setUniqueEventAttribute,
    setUniqueVenueAttribute,
    smallArtistCard,
    smallEventCard,
    smallVenueCard,
    toastSuccess
} from "../../components/common/utilities";
import moment from "moment";
import 'moment/locale/en-gb';
import {Dialog, Field, Label, Switch, Transition} from "@headlessui/react";
import mapboxgl from "mapbox-gl";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import Map, {GeolocateControl, Marker} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import {SchemaEvent} from "../../components/common/dataTypes";
import {ToastContainer} from "react-toastify";
import {Footer, Header} from "../../components/common/headerAndFooter";

function classNames(...classes: any[])
{
    return classes.filter(Boolean).join(' ')
}

export default function ViewVenue() {
    let params = useParams();
    const venueID = params.venueID;
    const data = useLoaderData() as any;
    //console.log("params", params);



    const text = useRef("");
    const inFocus = useRef("");
    const [artists, setArtists] = useState(data.artists);
    const [photos, setPhotos] = useState([]);
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffffff");
    const [date, setDate] = useState(null)
    const [venueData, setVenueData] = useState<SchemaEvent>();
    const [eventList, setEventList] = useState([]);
    const [showMedia, setShowMedia] = useState(false);
    const [wholeDay, setWholeDay] = useState(false);
    const [lang, setLang] = useState(getLang());
    const [open, setOpen] = useState(false);
    const [showAI, setShowAI] = useState(false);


    function getLang()
    {
        if (navigator.languages != undefined) return navigator.languages[0];
        return navigator.language;
    }

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN as string;



    const getEvents = (nextOffset: any) => {
        axios.get(`${process.env.REACT_APP_CACHE_URL}/p/events/upcoming.do`, {
            params: {
                instance: 'promogogo',
                limit: 10, offset: nextOffset, venue: params.venueID, light: true
            }

        }).then(function (response) {
            // handle success
            console.log("events", response);
            setEventList(response.data.collection);

        });
    }

    const toggleMedia = () => {
        if (showMedia)
        {
            setShowMedia(false);
        }
        else
        {
            setShowMedia(true);
        }
    }

    const renderMap = (latitude: any, longitude: any) => {
        const map = new mapboxgl.Map({
                                         container: 'map',
                                         style: 'mapbox://styles/mapbox/streets-v12',
                                         center: [longitude, latitude],
                                         zoom: 14,
                                         interactive: true,


                                     });
        /*map.on('click', function (e) {
            var coordinates = e.lngLat;

            //console.info('map clicked', coordinates)
            startMarker.setLngLat([coordinates.lng, coordinates.lat]);
            /!*new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setHTML('you clicked here: <br/>' + coordinates)
                .addTo(map);*!/
        });*/
        const startMarker = new mapboxgl.Marker()
            .setLngLat([longitude, latitude])
            .addTo(map);


    }


    useEffect(() => {

        setVenueData(data)
        if (data.geo && data.geo.latitude)
        {
            mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN as string;
            setTimeout(() => renderMap(data.geo.latitude, data.geo.longitude), 1000);
        }
        getEvents(0);
        //getPhotos();
        //handleArtists(data);

    }, []);

    const pagedata = useLoaderData() as any;
    let faceguid = '';



    return (

        <div className="min-h-full">
            <MetaTags
                title={venueData && venueData.name}
                description={venueData && venueData.description}
                image={venueData && venueData.image}/>
            <Header/>

            <div className="md:p-2">

                <main>

                    <div className="grid sm:grid-cols-3 gap-4">
                        <div className="col-span-2">
                            <div className="mt-2 mb-2 rounded-lg bg-white p-2">
                                {!venueData && <div className="p-4 text-gray-800 flex justify-center">
                                    <div className={'text-pg_yellow'}><i
                                            className="fa-2x fa-duotone fa-loader fa-spin"></i></div>
                                </div>}
                                {venueData && <>
                                    <div className="">
                                        {overlayCard(venueData)}

                                        <div className="md:px-4 py-5 sm:p-0">
                                            <div className=" md:px-4 sm:mt-16 sm:px-0 lg:mt-0">
                                                {venueData.description && <div className="mt-4"
                                                                               dangerouslySetInnerHTML={{__html: venueData.description}}></div>}


                                            </div>
                                            <div className=" md:px-4 sm:mt-16 sm:px-0 lg:mt-4">

                                                <div className="min-w-0 flex-1 pt-2 pb-2">
                                                    <div className="focus:outline-none">
                                                        <span className="absolute inset-0" aria-hidden="true"/>
                                                        <p className="truncate text-sm text-gray-500">Street: {venueData.address && venueData.address.streetAddress} </p>
                                                        <p className="truncate text-sm text-gray-500">City: {venueData.address && venueData.address.addressLocality}</p>
                                                        {venueData.address && venueData.address.addressRegion &&
                                                         <p className="truncate text-sm text-gray-500">Region: {venueData.address && venueData.address.addressRegion}</p>

                                                        }
                                                        <p className="truncate text-sm text-gray-500">Country: {venueData.address && venueData.address.addressCountry}</p>

                                                    </div>
                                                </div>

                                                <div id="geocoder" className="geocoder"></div>
                                                <div id={'map'}></div>

                                            </div>


                                        </div>
                                    </div>
                                </>}
                            </div>
                        </div>
                        <div className="md:col-span-1">
                            <div className="px-1 sm:mt-4 sm:px-0 lg:mt-0 w-full">

                                <div>
                                    <h3 className={'text-2xl font-bold tracking-tight text-white'}>Viðburðir</h3>
                                    <div className="mt-2 w-full">
                                        {eventList.map((event, index) => (<div key={event['@id']} className={'w-full'}>
                                            {smallEventCard(event)}
                                        </div>))}

                                    </div>

                                    <div className="px-4 sm:px-6 lg:px-8 mt-2">
                                        <div className="sm:flex sm:items-center">


                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </main>




            </div>

            <Footer/>

            <ToastContainer autoClose={800} theme={'dark'}/>
        </div>
)
}
