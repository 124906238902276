import { useRouteError } from "react-router-dom";
import React from "react";

export default function ErrorPage() {
    const error:any = useRouteError();
    console.error(error);

    return (


        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 bg-white">
            <div className="flex">
                <img
                    id="promo-icon"
                    className="h-16 w-auto animate-bounce"
                    src="https://cached.promogogo.com/styles/promogogo/promo_heart_yellow_256px.png"
                    alt="Promogogo"
                />
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

                <div id="error-page">
                    <h1 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Oops!</h1>
                    <p className="text-center text-gray-900">Sorry, an unexpected error has occurred.</p>
                    <p className="text-center text-gray-900">
                        <i>{error.statusText || error.message}</i>
                    </p>
                </div>

                <p className="mt-10 text-center text-sm text-gray-500">
                    Need a way out? <a href="/"
                                       className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Start
                    over</a>
                </p>
            </div>
        </div>);
}
