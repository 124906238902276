import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, Navigate, RouterProvider, useLocation} from "react-router-dom";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {HelmetProvider} from "react-helmet-async";
import {MetaTags} from "./components/common/utilities";
import ErrorPage from "./pages/ErrorPage";
import ViewEvent from "./pages/events/viewEvent";
import axios from "axios";
import ViewArtist from "./pages/attractions/viewArtist";
import ViewVenue from "./pages/venues/viewVenue";
import Mediaiceland from "./pages/documentation/mediaiceland";
import Admarketiceland from "./pages/documentation/admarketiceland";
import Whatarewedoingforthemedia from "./pages/documentation/whatarewedoingforthemedia";
import AddEventsIceland from "./pages/documentation/addeventsiceland";
import FindEvents from "./pages/events/findEvents";

let pagetitle = 'Mobilitus.is';

const router = createBrowserRouter([{
    path: "/", element: <App/>, errorElement: <ErrorPage/>,
}, {
    path: "/event/:wildcard/:eventID",
    element: <ViewEvent/>,
    errorElement: <ErrorPage/>,
    loader: async ({params}) => {
        pagetitle = 'Event';

        return axios.get(`${process.env.REACT_APP_CACHE_URL}/p/api/event.do?`, {
            params: {
                event: params.eventID,
                tiny: true,
                lang: 'is',
            }
        }).then(function (response) {
            let location = response.data;
            pagetitle = location.name;
            return location;


        }).catch(function (error) {
            console.log('error', error);

        });
    },
} , {
    path: "/event/:eventID",
    element: <ViewEvent/>,
    errorElement: <ErrorPage/>,
    loader: async ({params}) => {

        return axios.get(`${process.env.REACT_APP_CACHE_URL}/p/api/event.do?`, {
            params: {
                event: params.eventID,
                tiny: true,
                lang: 'is',
            }
        }).then(function (response) {
            //console.log('response status: ' + response.status);

            /*try
            {
                JSON.parse(response.data);
            }
            catch (e)
            {
                localStorage.removeItem('ticket');
                window.location.href = '/loginuser';
            }*/

            // handle success
            //console.log(response);
            let location = response.data;
            pagetitle = location.name;
            return location;


        }).catch(function (error) {
            console.log('error', error);

        });
    },
},{
    path: "/:wildcard/artist/:artistID",
    element: <ViewArtist/>,
    errorElement: <ErrorPage/>,
    loader: async ({params}) => {

        return axios.get(`${process.env.REACT_APP_CACHE_URL}/p/api/artist.do?`, {
            params: {
                artist: params.artistID,
                tiny: true,
            }
        }).then(function (response) {
            let location = response.data;
            pagetitle = location.name;
            return location;


        }).catch(function (error) {
            console.log('error', error);

        });
    },
} , {
    path: "/artist/:artistID",
    element: <ViewArtist/>,
    errorElement: <ErrorPage/>,
    loader: async ({params}) => {

        return axios.get(`${process.env.REACT_APP_CACHE_URL}/p/api/artist.do?`, {
            params: {
                artist: params.artistID,
                tiny: true,
            }
        }).then(function (response) {
            let location = response.data;
            pagetitle = location.name;
            return location;


        }).catch(function (error) {
            console.log('error', error);
        });
    },
}, {
    path: "/venue/:venueID",
    element: <ViewVenue/>,
    errorElement: <ErrorPage/>,
    loader: async ({params}) => {

        return axios.get(`${process.env.REACT_APP_CACHE_URL}/p/api/venue.do?`, {
            params: {
                venue: params.venueID,
                tiny: true,
            }
        }).then(function (response) {
            let location = response.data;
            pagetitle = location.name;
            return location;


        }).catch(function (error) {
            console.log('error', error);
        });
    },
},{
    path: "/findevent", element: <FindEvents/>, errorElement: <ErrorPage/>,
    loader: async ({params}) => {
        pagetitle = 'Find event';
        return {};
    },
},{
    path: "/media", element: <Mediaiceland/>, errorElement: <ErrorPage/>,
},{
    path: "/admarket", element: <Admarketiceland/>, errorElement: <ErrorPage/>,
},{
    path: "/forthemedia", element: <Whatarewedoingforthemedia/>, errorElement: <ErrorPage/>,
} ,{
    path: "/addevent", element: <AddEventsIceland/>, errorElement: <ErrorPage/>,
                                       loader: async ({params}) => {
    pagetitle = 'Add event';
    return {};
},
}


]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<HelmetProvider>
    <MetaTags
        title='Mobilitus.is'
        description='Allir vi�bur�ir � �llum svi�um um allt land'
        image='https://media.promogogo.com/gjugg/2021-07-12/b76bd1a87b7d4d01a458f19e02612acd.png'
        name='Team Mobilitus'
    />
    <RouterProvider router={router}/>
</HelmetProvider>);

function sendToAnalytics():any {
    let digitalData = {
        "skipCount": false, "page": {
            "pageInfo": {
                "pageID": document.URL,
                "pageName": pagetitle,
                "destinationURL": document.location.href,
                "author": "Toti Stefansson",
                "issueDate": "2021-04-16T13:23:09.000Z",
                "publisher": "mobilitusehf",
                "instance": "promogogo",
                "category": {},
                "attributes": {
                    "posterImage": "https://media.promogogo.com/gjugg/2021-07-12/b76bd1a87b7d4d01a458f19e02612acd.png",
                    "posterImagearticlelist": "https://media.promogogo.com/2021-07-12/715365cdb9e34a6baa2e66880333ce15.png",
                    "posterImageDesc": "https://media.promogogo.com/gjugg/2021-07-12/b76bd1a87b7d4d01a458f19e02612acd.png",
                    "url": "gogopage-pheew",
                    "seoUrl": "gjugg-vidburdir",
                    "type": "LANDINGPAGE",
                    "pageUrl": document.URL,
                    "referrer": document.referrer,

                },
            }
        }
    };
    var pggTrackData = {
        faceUrl: "mobilitusehf",
        instance: "promogogo",
        digitalData
    };
    const headers = {
        type: 'application/json',
    };
    const body = new Blob([JSON.stringify(pggTrackData)],headers);
    const url = 'https://count.promogogo.com/v1';

    // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
    fetch(url, { body, method: 'POST', keepalive: true, headers: { "Content-Type": "application/json;charset=UTF-8" } });
    return true;
}

reportWebVitals(sendToAnalytics());
