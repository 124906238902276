import axios from "axios";
import moment from "moment";
import React from "react";
import {toast} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import * as DOMPurify from 'dompurify';
import classNames from 'classnames';
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Truncate} from "@re-dev/react-truncate";
import {usePreferredLanguage} from "@uidotdev/usehooks";

const audiences = [{name: 'Hot Tickets', code: 'hottickets'}, {
    name: 'Innovation',
    code: 'innovation'
}, {name: 'Elderly', code: 'elderly'}, {
    name: '80s', code: '80s'
}, {name: '60s-70s', code: '6070'}, {name: '90s', code: '90s'}, {name: '00s', code: '00s'}, {
    name: '10s', code: '10s'
}, {name: '20s', code: '20s'}, {name: 'Kids', code: 'kids'}, {name: 'Teens', code: 'teens'}, {
    name: 'Students', code: 'students'
}, {name: 'Young Adults', code: 'youngadults'}, {name: 'Adults', code: 'adults'}, {
    name: 'Parents', code: 'parents'
}, {name: 'Families', code: 'families'}, {name: 'Couples', code: 'couples'}, {
    name: 'Singles', code: 'singles'
}, {name: 'LGBTQ', code: 'lgbtq'}, {name: 'Current affairs', code: 'currentaffairs'}, {
    name: 'Local interest',
    code: 'localinterest'
}, {name: 'Book lovers', code: 'booklovers'}, {name: 'Art lovers', code: 'artlovers'}, {
    name: 'Tourist Friendly',
    code: 'touristfriendly'
}, {name: 'Outdoors', code: 'outdoors'}, {name: 'Wellness', code: 'wellness'}, {
    name: 'Women',
    code: 'women'
}, {name: 'Foodies', code: 'foodies'}, {name: 'Drinkers', code: 'drinkers'}, {
    name: 'Music lovers',
    code: 'musiclovers'
},]

export const supportedLanguages = [{name: 'English', code: 'en'}, {name: 'Íslenska', code: 'is'}, {
    name: 'Polish',
    code: 'pl'
}, {
    name: 'Danish', code: 'da'
}, {name: 'Norwegian', code: 'no'}, {name: 'Swedish', code: 'se'},]

export const useLanguage = () => {
    const preferredLanguage = usePreferredLanguage();
    console.log('preferredLanguage', preferredLanguage);
    for (let i = 0; i < supportedLanguages.length; i++)
    {
        if (supportedLanguages[i].code === preferredLanguage)
        {
            return supportedLanguages[i].code;
        }
    }
    return 'en';
}

export function MetaTags({title = '', description = '', image = '', name = ''})
{
    return (<Helmet>
        { /* Standard metadata tags */}
        <title>{title}</title>
        <link rel='canonical' href={window.location.href}/>
        <meta name='description' content={description}/>
        { /* Open Graph tags (OG) */}
        <meta property="og:url" content={window.location.href}/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={description}/>
        {/* OG image tags */}
        <meta property="og:image" content={image}/>
        <meta property="og:image:secure_url" content={image}/>
        <meta property="og:image:type" content="image/jpeg"/>
        <meta property="og:image:width" content="200"/>
        <meta property="og:image:alt" content={`Image of ${title} site`}/>
        { /* Twitter tags */}
        <meta name="twitter:creator" content={name}/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content={title}/>
        <meta name="twitter:description" content={description}/>

    </Helmet>);
}

export function urlfriendly(text)
{
   let org = text.toLowerCase();
   org = org.trim();
    org = org.replaceAll("\\s", "-");
    org = org.replaceAll("_{2,}", "-");
    org = org.replaceAll("\"", "");
    org = org.replaceAll(" ", "-");
    org = org.replace(/[?!|,:~'`+*#$%&/()=@\{\}\[\]<>;…\.]/g, "");


    // Unwanted characters are removed
    org = org.replace(/[?!|,:~'`+*#$%&/()=@\\{\\}\\[\\]<>;]/g, "");
    org = org.replaceAll("---", "-");
    org = org.replaceAll("--", "-");
    org = org.replaceAll("á", "a");
    org = org.replaceAll("ð", "d");
    org = org.replaceAll("é", "e");
    org = org.replaceAll("í", "i");
    org = org.replaceAll("ó", "o");
    org = org.replaceAll("ö", "o");
    org = org.replaceAll("ú", "u");
    org = org.replaceAll("ý", "y");
    org = org.replaceAll("þ", "th");
    org = org.replaceAll("æ", "ae");
    org = org.replace(/[\\W&&[^\\-\\.\\/\\\\]]/g, "");
    //console.log('urlfriendly',org);

    return org;
}

export function getBestImage(json)
{

    if (json.bestImage)
    {
        return json.bestImage;
    }

    let attr = json.attributes;
    let bestPicture = '';
    if (attr && attr.length > 0)
    {
        for (let i = 0; i < attr.length; i++)
        {
            if (attr[i].key === 'bestPicture')
            {
                bestPicture = attr[i].value;
                break
            }
        }
    }
    return bestPicture;
}

export const getAttribute = (json, item) => {
    var value = '';
    //console.log('getAttributes from ', json);
    if (json['attributes'])
    {

        for (let i = 0; i < json['attributes'].length; i++)
        {
            //console.log(item+' from ', json['attributes'][i]);
            let attr = json['attributes'][i];
            //console.log('attr', attr.key);
            if (attr.key === item)
            {
                return attr.value
            }
        }

    }
    return value
}

export const hasAttribute = (json, item) => {
    var value = false;
    //console.log('getAttributes from ', json);
    if (json['attributes'])
    {

        for (let i = 0; i < json['attributes'].length; i++)
        {
            //console.log(item+' from ', json['attributes'][i]);
            let attr = json['attributes'][i];
            //console.log('attr', attr.key);
            if (attr.key === item)
            {
                return true
            }
        }

    }
    return value
}

export const hasAttributeAndValue = (json, key, value) => {
    var result = false;
    //console.log('getAttributes from ', json);
    if (json['attributes'])
    {

        for (let i = 0; i < json['attributes'].length; i++)
        {
            //console.log(item+' from ', json['attributes'][i]);
            let attr = json['attributes'][i];
            //console.log('attr', attr.key);
            if (attr.key === key && attr.value === value)
            {
                return true
            }
        }

    }
    return result
}

export function setAttribute(guid, key, value, unique, deleteFlag)
{
    //console.log('setAttribute',guid,key,value,unique,deleteFlag);
    let params = {
        "ticket": localStorage.getItem('ticket'),
        "instance": "keeps",
        "guid": guid,
        "key": key,
        "value": value,
        "unique": unique,
        "delete": deleteFlag,

    }
    axios.post(`${process.env.REACT_APP_API_URL}/keepsapi/api/manageattributes.do`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
    return true;
}

export function changeAttribute(guid, key, value, previous, unique, deleteFlag)
{
    console.log('setAttribute', guid, key, value, unique, deleteFlag);
    let params = {
        "ticket": localStorage.getItem('ticket'),
        "instance": "keeps",
        "guid": guid,
        "key": key,
        "value": value,
        "unique": false,
        "delete": deleteFlag,
        "previous": previous,
        "update": true

    }
    axios.post(`${process.env.REACT_APP_API_URL}/keepsapi/api/manageattributes.do`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
    return true;
}


export function setRoomAttribute(guid, key, value, unique, deleteFlag)
{
    console.log('setRoomAttribute', guid, key, value, unique, deleteFlag);
    let params = {
        "ticket": localStorage.getItem('ticket'),
        "instance": "keeps",
        "room": guid,
        "key": key,
        "value": value,
        "unique": unique,
        "delete": deleteFlag,

    }
    axios.post(`${process.env.REACT_APP_API_URL}/keepsapi/api/manageroomattributes.do`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
            .then(function (response) {
                console.log(response);
                if (response.data.ticket)
                {
                    localStorage.setItem('ticket', response.data.ticket);
                    window.location.href = '/dashboard';

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    return true;
}

export const getImageWithPrefix = (json, item, prefix) => {
    if (!prefix) prefix = 'https://promogogo.com/cdn-cgi/image/width=600,height=400,fit=pad,gravity=auto,background=black/';
    var value = '';
    //console.log('getAttributes from ', json);
    if (json['attributes'])
    {

        for (let i = 0; i < json['attributes'].length; i++)
        {
            //console.log(item+' from ', json['attributes'][i]);
            let attr = json['attributes'][i];
            //console.log('attr', attr.key);
            if (attr.key === item)
            {
                return prefix + attr.value
            }
        }

    }
    return value
}

export const getLocalDate = (datetime, timezone) => {
    if (!timezone) timezone = 'UTC';
    const moment = require('moment-timezone'); //moment-timezone
    var momentdate = moment(datetime);


    return momentdate.tz(timezone).format('ddd MMM Do YYYY HH:mm');
}

export const getLocalDateForEvent = (event, datetime, timezone) => {
    if (!timezone) timezone = 'UTC';
    const moment = require('moment-timezone'); //moment-timezone
    var momentdate = moment(datetime);

    if (event.gogo && event.gogo.wholeDay === true)
    {
        return momentdate.format('ddd MMM Do YYYY');

    }

    return momentdate.tz(timezone).format('ddd MMM Do YYYY HH:mm');
}

export const getDate = (datetime, timezone) => {
    if (!timezone) timezone = 'UTC';
    var momentdate = moment(datetime);


    return momentdate.format('ddd MMM Do YYYY HH:mm z');
}

export async function copyTextToClipboard(text)
{
    if ('clipboard' in navigator)
    {
        toastSuccess('Copied to clipboard', {autoClose: 2000, theme: "dark"})
        return await navigator.clipboard.writeText(text);
    }
    else
    {
        toastSuccess('Copied to clipboard', {autoClose: 2000, theme: "dark"})
        return document.execCommand('copy', true, text);
    }
}

export const setUniqueEventAttribute = (key, value, unique, remove, eventid) => {
    console.log('setting ', key, value);
    let params = {
        key: key, value: value, eventid: eventid, ticket: localStorage.getItem('ticket'), addunique: unique,
    };
    if (remove === true && unique === true)
    {
        console.log('removing unique', key, value);
        params = {
            key: key, value: value, eventid: eventid, ticket: localStorage.getItem('ticket'), delunique: true,
        }
    }
    else if (remove === true && unique === false)
    {
        console.log('removing ', key, value);
        params = {
            key: key, value: value, eventid: eventid, ticket: localStorage.getItem('ticket'), del: true,
        }
    }
    else if (unique === false)
    {
        console.log('adding ', key, value);
        params = {
            key: key, value: value, eventid: eventid, ticket: localStorage.getItem('ticket'), add: true,
        }

    }

    axios.post(`${process.env.REACT_APP_API_URL}/p/manage/event/change.do`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(function (response) {
        // handle success
        console.log(response);
        //setRooms(response.data.collection);
        //window.location.href = '/onboarding/room/'+response.data.collection[0]['guid'];

    }).catch(function (error) {
        // handle error
        console.log(error);
        return error;
    });
}

export const addEventAttribute = (key, value, remove, eventid) => {
    let params = {
        key: key, value: value, eventid: eventid, ticket: localStorage.getItem('ticket'), add: true,
    };
    if (remove === 'true')
    {
        console.log('removing ', key, value + " for " + eventid);
        params = {
            key: key, value: value, eventid: eventid, ticket: localStorage.getItem('ticket'), del: true,
        }
    }
    else
    {
        console.log('setting ', key, value + " for " + eventid);

    }
    axios.post(`${process.env.REACT_APP_API_URL}/p/manage/event/change.do`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(function (response) {
        // handle success
        console.log(response);
        //setRooms(response.data.collection);
        //window.location.href = '/onboarding/room/'+response.data.collection[0]['guid'];

    });
}

export const setUniqueAttractionAttribute = (key, value, unique, remove, eventid) => {
    console.log('setting ', key, value);
    let params = {
        key: key,
        value: value,
        artist: eventid,
        ticket: localStorage.getItem('ticket'),
        addunique: unique,
        command: 'setunique'
    };
    if (remove === 'true' && unique === 'true')
    {
        console.log('removing unique', key, value);
        params = {
            key: key,
            value: value,
            artist: eventid,
            ticket: localStorage.getItem('ticket'),
            delunique: true,
            command: 'delunique'
        }
    }
    else if (remove === 'true' && unique === 'false')
    {
        console.log('removing ', key, value);
        params = {
            key: key, value: value, artist: eventid, ticket: localStorage.getItem('ticket'), del: true, command: 'del'
        }
    }
    else if (unique === false)
    {
        console.log('adding ', key, value);
        params = {
            key: key, value: value, artist: eventid, ticket: localStorage.getItem('ticket'), add: true, command: 'add'
        }

    }

    axios.post(`${process.env.REACT_APP_API_URL}/p/manage/performer/change.do`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(function (response) {
        // handle success
        console.log(response);
        //setRooms(response.data.collection);
        //window.location.href = '/onboarding/room/'+response.data.collection[0]['guid'];

    });
}

export const inheritAudiences = (artistid, eventid, artisttoupdate) => {
    console.log('inheritAudiences', artistid, eventid, artisttoupdate);
    let params = {
        event: eventid, updateartist: artisttoupdate, artist: artistid, ticket: localStorage.getItem('ticket')
    };

    axios.post(`${process.env.REACT_APP_API_URL}/p/manage/audiences.do`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(function (response) {
        // handle success
        if (response.data.success)
        {
            toastSuccess(response.data.success);

        }
        else
        {
            toastSuccess("Something went wrong");
        }

        console.log(response);

    });
}

export const manageAttractionData = (key, value, artistid) => {
    console.log('setting ', key, value);
    let params = {
        type: key, value: value, artist: artistid, ticket: localStorage.getItem('ticket')
    };

    axios.post(`${process.env.REACT_APP_API_URL}/p/manage/performer/data/change.do`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(function (response) {
        // handle success
        if (response.data.success)
        {
            toastSuccess(response.data.success);

        }
        else
        {
            toastSuccess("Something went wrong");
        }

        console.log(response);

    });
}

export const setUniqueVenueAttribute = (key, value, unique, remove, eventid) => {
    console.log('setting ', key, value);
    let params = {
        key: key,
        value: value,
        venue: eventid,
        ticket: localStorage.getItem('ticket'),
        addunique: unique,
        command: 'setunique'
    };
    if (remove === 'true' && unique === 'true')
    {
        console.log('removing unique', key, value);
        params = {
            key: key,
            value: value,
            venue: eventid,
            ticket: localStorage.getItem('ticket'),
            delunique: true,
            command: 'delunique'
        }
    }
    else if (remove === 'true' && unique === 'false')
    {
        console.log('removing ', key, value);
        params = {
            key: key, value: value, venue: eventid, ticket: localStorage.getItem('ticket'), del: true, command: 'del'
        }
    }
    else if (unique === false)
    {
        console.log('adding ', key, value);
        params = {
            key: key, value: value, venue: eventid, ticket: localStorage.getItem('ticket'), add: true, command: 'add'
        }

    }

    axios.post(`${process.env.REACT_APP_API_URL}/p/manage/venue/change.do`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(function (response) {
        // handle success
        console.log(response);
        //setRooms(response.data.collection);
        //window.location.href = '/onboarding/room/'+response.data.collection[0]['guid'];

    });
}

export const manageVenueData = (key, value, artistid) => {
    console.log('setting ', key, value, ' for ', artistid);
    let params = {
        type: key, value: value, venue: artistid, ticket: localStorage.getItem('ticket')
    };

    axios.post(`${process.env.REACT_APP_API_URL}/p/manage/venue/data/change.do`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(function (response) {
        // handle success
        if (response.data.success)
        {
            toastSuccess(response.data.success);

        }
        else
        {
            toastSuccess("Something went wrong");
        }

        console.log(response);

    });
}

export const manageActionItem = (key, value) => {

    axios.post(`${process.env.REACT_APP_API_URL}/p/manage/actionitem.do`, {
        key: key,
        value: value,
        ticket: localStorage.getItem('ticket')
    }, {
                   headers: {
                       'Content-Type': 'application/x-www-form-urlencoded'
                   }
               }).then(function (response) {
        // handle success
        console.log(response);
        toast("Resolved", {autoClose: 2000, theme: "dark"})


    });
}

export const isButtonActive = (event, key, value) => {
    //console.log('isButtonActive', event, key, value)
    if (event.admin && event.admin.audience)
    {
        let theaudience = event.admin.audience;
        //console.log('isButtonActive', event.admin.audience, key, value)
        if (theaudience.includes(key))
        {
            return true;
        }
    }
    return false;

}

export const setDefaultPerformer = (performer) => {
    localStorage.setItem("defaultPerformer", JSON.stringify(performer));
}

export const getDefaultPerformer = () => {
    const performer = localStorage.getItem("defaultPerformer");
    if (performer && performer.length > 0)
    {
        //console.log('defaultPerformer', performer);
        return JSON.parse(performer);
    }
    return null;
}

export const setDefaultVenue = (location) => {
    localStorage.setItem("defaultVenue", JSON.stringify(location));
}
export const getDefaultVenue = () => {
    const location = localStorage.getItem("defaultVenue");
    if (location && location.length > 0)
    {
        //console.log('defaultVenue', location);
        return JSON.parse(location);
    }
    return null;
}

export const thumbnailPad = (imageurl) => {
    return <img
            className="h-20 flex-none rounded bg-gray-50"
            src={'https://promogogo.com/cdn-cgi/image/width=1280,height=720,fit=pad/' + imageurl} alt=""/>
}
export const imagePad = (imageurl) => {
    return <img
            className="h-36 flex-none rounded-lg bg-gray-50"
            src={'https://promogogo.com/cdn-cgi/image/width=1280,height=720,fit=pad/' + imageurl} alt=""/>
}

export const mergeVenues = (venueid, mergevenueid) => {
    if (mergevenueid === venueid)
    {
        toastSuccess('Cannot merge venue with itself');
        return;
    }
    axios.post(`${process.env.REACT_APP_API_URL}/p/manage/merge.do`, {
        instance: 'promogogo', ticket: localStorage.getItem('ticket'), venue: venueid, mergevenue: mergevenueid

    }, {
                   headers: {
                       'Content-Type': 'application/x-www-form-urlencoded',
                   }
               }).then(function (response) {
        // handle success
        //console.log(response);
        //console.log('merged', response.data);
        toastSuccess(response.data.success);


    }).catch(function (error) {
        // handle error
        console.log(error);
        return error;
    });
}

export const mergeAttractions = (attractionid, mergeattractionid) => {
    if (mergeattractionid === attractionid)
    {
        toastSuccess('Cannot merge attraction with itself');
        return;
    }
    axios.post(`${process.env.REACT_APP_API_URL}/p/manage/merge.do`, {
        instance: 'promogogo',
        ticket: localStorage.getItem('ticket'),
        artist: attractionid,
        mergeartist: mergeattractionid

    }, {
                   headers: {
                       'Content-Type': 'application/x-www-form-urlencoded',
                   }
               }).then(function (response) {
        // handle success
        //console.log(response);
        //console.log('merged', response.data);
        toastSuccess(response.data.success);


    }).catch(function (error) {
        // handle error
        console.log(error);
        return error;
    });
}

export const mergeEvents = (eventid, mergeeventid) => {
    if (mergeeventid === eventid)
    {
        toastSuccess('Cannot merge attraction with itself');
        return;
    }
    axios.post(`${process.env.REACT_APP_API_URL}/p/manage/merge.do`, {
        instance: 'promogogo', ticket: localStorage.getItem('ticket'), event: eventid, mergeevent: mergeeventid

    }, {
                   headers: {
                       'Content-Type': 'application/x-www-form-urlencoded',
                   }
               }).then(function (response) {
        // handle success
        //console.log(response);
        //console.log('merged', response.data);
        toastSuccess(response.data.success);


    }).catch(function (error) {
        // handle error
        console.log(error);
        return error;
    });
}


export const audienceButtons = (event) => {

    let className = 'm-1 relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-red-400 hover:text-white focus:z-10'
    let classNameActive = 'm-1 relative -ml-px inline-flex items-center bg-green-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 hover:text-white focus:z-10'

    return <>
        {audiences.map((audience, index) => <>
            <button
                    key={audience.code}
                    className={isButtonActive(event, audience.code, audience.code) ? classNameActive : className}
                    data-button={'manageAudience'} data-key={audience.code} data-value={audience.code}
                    data-unique={true}
                    data-remove={isButtonActive(event, audience.code, audience.code)}
                    data-eventid={event['@id']}>{audience.name}</button>
        </>)

        }
    </>
}

export const smallEventCard = (event) => {
    return (<div
            className="relative w-full flex mt-1 items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-pg_green"

    >
        <div className="flex-shrink-0 w-1/4">
            {event.image && (<img className="h-20 flex-shrink-0 rounded-l bg-gray-300 object-cover"
                                  src={'https://promogogo.com/cdn-cgi/image/width=230,fit=cover/' + event.image}
                                  alt=""/>)}
            {!event.image && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300 "/>)}
        </div>
        <div className="min-w-0 flex-1 pt-2 pb-2">
            <a href={'/event/'+urlfriendly(event.name)+"/" + event['@id']} className="focus:outline-none" target={'_blank'}>
                <span className="absolute inset-0" aria-hidden="true"/>
                <p className="text-sm font-medium text-gray-900">{event.name}</p>
                <p className="truncate text-sm text-gray-700">{event.location && event.location.name}</p>
                {event && event.gogo && event.gogo.wholeDay && (
                        <p className="truncate text-sm text-gray-500">{getLocalDateForEvent(event, event.startDate, event.timezone)}</p>)}
                {event && !event.gogo || !event.gogo.wholeDay && (
                        <p className="truncate text-sm text-gray-500">{getLocalDateForEvent(event, event.startDate, event.timezone)}</p>)}
            </a>
        </div>
    </div>)
}

export const eventHeaderCard = (event) => {
    return (<div
            className="relative flex mt-1 items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-pg_green"

    >
        <div className="flex-shrink-0 w-1/4">
            {event.image && (<img className="w-40 flex-shrink-0 rounded-lg bg-gray-300 object-cover"
                                  src={'https://promogogo.com/cdn-cgi/image/width=230,fit=cover/' + event.image}
                                  alt=""/>)}
            {!event.image && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300 "/>)}
        </div>
        <div className="min-w-0 flex-1 pt-2 pb-2">
            <span className="absolute inset-0" aria-hidden="true"/>
            <p className="text-sm font-medium text-gray-900">{event.name}</p>
            <p className="truncate text-sm text-gray-700">{event.location && event.location.name}</p>
            {event && event.gogo && event.gogo.wholeDay && (
                    <p className="truncate text-sm text-gray-500">{getLocalDateForEvent(event, event.startDate, event.timezone)}</p>)}
            {event && !event.gogo || !event.gogo.wholeDay && (
                    <p className="truncate text-sm text-gray-500">{getLocalDateForEvent(event, event.startDate, event.timezone)}</p>)}
        </div>
    </div>)
}

export const spotifyPlayer = (artist) => {
    if (artist.gogo && artist.gogo.spotify)
    {
        let spotifyurl = artist.gogo.spotify;
        if (spotifyurl.includes('spotify.com/artist'))
        {
            spotifyurl = spotifyurl.replace('spotify.com/artist', 'spotify.com/embed/artist');
        }
        return (<iframe src={spotifyurl}
                        width="360" height="380" frameBorder="0" allowTransparency="true"
                        allow="encrypted-media"/>)
    }
    return null;
}

export const promotedEventCard = (event) => {
    return (<div
            className="relative mt-1 items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-pg_green"

    >
        <div className="flex-shrink-0 w-100">
            {event.image && (<img className="w-100 flex-shrink-0 rounded-l bg-gray-300 object-cover"
                                  src={event.image}
                                  alt=""/>)}
            {!event.image && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300 "/>)}
        </div>
        <div className="min-w-0 flex-1 pt-2 pb-2">
            <a href={'/event/'+urlfriendly(event.name)+'/' + event['@id']} className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true"/>
                <p className="text-sm font-medium text-gray-900">{event.name}</p>
                <p className="truncate text-sm text-gray-700">{event.location && event.location.name}</p>
                {event && event.gogo && event.gogo.wholeDay && (
                        <p className="truncate text-sm text-gray-500">{getLocalDateForEvent(event, event.startDate, event.timezone)}</p>)}
                {event && !event.gogo || !event.gogo.wholeDay && (
                        <p className="truncate text-sm text-gray-500">{getLocalDateForEvent(event, event.startDate, event.timezone)}</p>)}
            </a>
        </div>
    </div>)
}

export const bigArtistCard = (artist) => {
    return (<div key={artist['@id']}>
        <a href={'/' +urlfriendly(artist.name)+ "/artist/"+ artist['@id']} className="focus:outline-none">
            <div>
                <img
                        src={'https://promogogo.com/cdn-cgi/image/width=400,height=300,fit=cover/' + artist.image}
                        alt=""
                        className="hover:opacity-75"/>
            </div>
            <h3 className={'text-md font-semibold text-gray-900 mt-4'}
            >{artist.name}</h3>
            {artist.description && <div className={'text-sm text-gray-500 mt-2'}
                                        onClick={(e) => copyTextToClipboard(artist.description.replace(/<[^>]*>?/gm, ' '))}
            >
                <Truncate
                        lines={3}
                        ellipsis={<span>
          ...
        </span>}

                >
                    {artist.description.replace(/<[^>]*>?/gm, '')}
                </Truncate>

            </div>}
        </a>
    </div>)
}

export const overlayCard = (artist) => {

    return <div className={'relative'}>
        <div
                className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
            <img
                    src={'https://promogogo.com/cdn-cgi/image/width=1024,height=768,fit=cover/' + artist.image}
                    alt=""
                    className="pointer-events-none object-cover group-hover:opacity-75"/>
            <div className="pointer-events-none font-medium text-white overlay overlay_1"><span
                    className={'truncate text-ellipsis'}>{artist.name}</span></div>

        </div>
    </div>
}

export const mergeEventsCard = (event, original) => {
    return (<div
            className="relative flex mt-1 items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-pg_green"

    >
        <div className="flex-shrink-0 w-1/4 ">
            {event.image && (<img className="h-20 flex-shrink-0 rounded-l bg-gray-300 object-cover"
                                  src={'https://promogogo.com/cdn-cgi/image/width=230,fit=cover/' + event.image}
                                  alt=""/>)}
            {!event.image && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300 "/>)}
        </div>
        <div className="min-w-0 flex-1 pt-2 pb-2">
            <p className="text-sm font-medium text-gray-900">{event.name}</p>
            <p className="truncate text-sm text-gray-700">{event.location && event.location.name}</p>
            {event && event.gogo && event.gogo.wholeDay && (
                    <p className="truncate text-sm text-gray-500">{getLocalDateForEvent(event, event.startDate, event.timezone)}</p>)}
            {event && !event.gogo || !event.gogo.wholeDay && (
                    <p className="truncate text-sm text-gray-500">{getLocalDateForEvent(event, event.startDate, event.timezone)}</p>)}
            <div className="grid grid-cols-2 col-span-1  items-center">
                <div className={'col-span-1 m-2 cursor-pointer text-green-900'}
                     data-button={'mergeEvent'}
                     data-eventid={event['@id']}>
                    <i className="fa-duotone fa-solid fa-arrow-left"></i> Merge
                </div>
                <div className={'col-span-1 m-2 cursor-pointer text-pg_purple'}
                     data-button={'mergeWithThatEvent'}
                     data-attractionid={event['@id']}
                > Merge <i
                        className="fa-duotone fa-solid fa-arrow-right"></i>
                </div>
            </div>
        </div>
    </div>)
}

export const fastSearchCard = (event, buttontype) => {
    let buttonType = 'linkTo';
    if (buttontype)
    {
        //console.log(buttonType);
        buttonType = buttontype;
    }
    if (event.type === 'attraction')
    {
        return (<div
                        key={event.id}
                        className="mt-2 relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-pg_green"
                >
                    <div className="flex-shrink-0 w-1/4">
                        {event.imageurl && (<img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300 object-cover"
                                                 src={'https://promogogo.com/cdn-cgi/image/width=230,fit=pad/' + event.imageurl}
                                                 alt=""/>)}
                        {!event.imageurl && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300 "/>)}
                    </div>
                    <div className="min-w-0 flex-1 pt-2 pb-2">
                        <div>
                <span className="absolute inset-0" aria-hidden="true"
                      data-button={buttonType} data-key={'gjuggad'} data-value={'true'} data-unique={true}
                      data-remove={true} data-artistid={event.id}
                />
                            <p className="text-sm font-medium text-gray-900">{event.displayname}</p>
                            <p className="truncate text-sm text-gray-700">{event.type && event.type === 'attraction' && <>Performer</>}{event.type && event.type === 'venue' && <>Staður</>}</p>
                            <p className="truncate text-sm text-gray-500">{event.majortype} {event.country}</p>
                        </div>
                    </div>
                </div>

        )
    }
    if (event.type === 'venue')
    {
        return (<div
                        key={event.id}
                        className="mt-2 relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-pg_green"
                >
                    <div className="flex-shrink-0 w-1/4">
                        {event.imageurl && (<img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300 object-cover"
                                                 src={'https://promogogo.com/cdn-cgi/image/width=230,fit=pad/' + event.imageurl}
                                                 alt=""/>)}
                        {!event.imageurl && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300 "/>)}
                    </div>
                    <div className="min-w-0 flex-1 pt-2 pb-2">
                        <div>
                <span className="absolute inset-0" aria-hidden="true"
                      data-button={buttonType} data-key={'gjuggad'} data-value={'true'} data-unique={true}
                      data-remove={true} data-venueid={event.id}
                />
                            <p className="text-sm font-medium text-gray-900">{event.displayname}</p>
                            <p className="truncate text-sm text-gray-700">{event.type && event.type === 'attraction' && <>Performer</>}{event.type && event.type === 'venue' && <>Staður</>}</p>
                            <p className="truncate text-sm text-gray-500">{event.address1} {event.city}</p>
                        </div>
                    </div>
                </div>)
    }
    if (event.type === 'event')
    {
        return (<div
                key={event.id}
                className="mt-2 relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-pg_green"

        >
            <div className="flex-shrink-0 w-1/4">
                {event.imageurl && (<img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300 object-cover"
                                         src={'https://promogogo.com/cdn-cgi/image/width=230,fit=pad/' + event.imageurl}
                                         alt=""/>)}
                {!event.imageurl && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300 "/>)}
            </div>
            <div className="min-w-0 flex-1 pt-2 pb-2">
                <div>
                <span className="absolute inset-0" aria-hidden="true"
                      data-button={buttonType} data-venueid={event['@id']}
                />
                    <p className="text-sm font-medium text-gray-900">{event.displayname}</p>
                    <p className="text-sm font-medium text-gray-900">{getLocalDateForEvent(event, event.when, null)}</p>
                    <p className="truncate text-sm text-gray-700">{event.type && event.type === 'attraction' && <>Performer</>}{event.type && event.type === 'venue' && <>Staður</>}</p>
                    <p className="truncate text-sm text-gray-500">{event.address1} {event.city}</p>
                </div>
            </div>
        </div>)
    }
}

export const fastLinkedSearchCard = (event) => {
    if (event.type === 'attraction')
    {
        return (<a href={'/attraction/' + event.id}>
                    <div
                            key={event.id}
                            className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-pg_green"
                    >
                        <div className="flex-shrink-0 w-1/4">
                            {event.imageurl && (
                                    <img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300 object-cover"
                                         src={'https://promogogo.com/cdn-cgi/image/width=230,fit=pad/' + event.imageurl}
                                         alt=""/>)}
                            {!event.imageurl && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300 "/>)}
                        </div>
                        <div className="min-w-0 flex-1 pt-2 pb-2">
                            <div>
                                <p className="text-sm font-medium text-gray-900">{event.displayname}</p>
                                <p className="truncate text-sm text-gray-700">{event.type && event.type === 'attraction' && <>Performer</>}{event.type && event.type === 'venue' && <>Staður</>}</p>
                                <p className="truncate text-sm text-gray-500">{event.majortype} {event.country}</p>
                            </div>
                        </div>
                    </div>
                </a>)
    }
    if (event.type === 'venue')
    {
        return (<a href={'/venue/' + event.id}>
                    <div
                            key={event.id}
                            className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-pg_green"
                    >
                        <div className="flex-shrink-0 w-1/4">
                            {event.imageurl && (
                                    <img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300 object-cover"
                                         src={'https://promogogo.com/cdn-cgi/image/width=230,fit=pad/' + event.imageurl}
                                         alt=""/>)}
                            {!event.imageurl && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300 "/>)}
                        </div>
                        <div className="min-w-0 flex-1 pt-2 pb-2">
                            <div>
                                <p className="text-sm font-medium text-gray-900">{event.displayname}</p>
                                <p className="truncate text-sm text-gray-700">{event.type && event.type === 'attraction' && <>Performer</>}{event.type && event.type === 'venue' && <>Staður</>}</p>
                                <p className="truncate text-sm text-gray-500">{event.address1} {event.city}</p>
                            </div>
                        </div>
                    </div>
                </a>)
    }
    if (event.type === 'event')
    {
        return (<a href={'/event/' + event.id}>
            <div
                    key={event.id}
                    className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-pg_green"

            >
                <div className="flex-shrink-0 w-1/4">
                    {event.imageurl && (<img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300 object-cover"
                                             src={'https://promogogo.com/cdn-cgi/image/width=230,fit=pad/' + event.imageurl}
                                             alt=""/>)}
                    {!event.imageurl && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300 "/>)}
                </div>
                <div className="min-w-0 flex-1 pt-2 pb-2">
                    <div>
                        <p className="text-sm font-medium text-gray-900">{event.displayname}</p>
                        <p className="text-sm font-medium text-gray-900">{getLocalDateForEvent(event, event.when, null)}</p>
                        <p className="truncate text-sm text-gray-700">{event.type && event.type === 'attraction' && <>Performer</>}{event.type && event.type === 'venue' && <>Staður</>}</p>
                        <p className="truncate text-sm text-gray-500">{event.address1} {event.city}</p>
                    </div>
                </div>
            </div>
        </a>)
    }
}

export const manageEventCard = (event) => {
    var artists = [];
    if (event.performers)
    {
        artists = event.performers;
        //console.log('adminEventCard',artists)

    }
    var adBtnGroupClasses = classNames('relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10', {
        'bg-green-500': event.gogo && event.gogo.ad,
        'text-white': event.gogo && event.gogo.ad,
        'bg-white': !event.gogo || !event.gogo.ad,
        'text-gray-900': !event.gogo || !event.gogo.ad,
    });

    return (<>
                <div
                        key={'manageEventCard' + event['@id']}
                        className="relative flex space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-pg_green"

                >
                    <div className="flex-shrink-0">
                        {event.image && (<img className="h-30 w-40 flex-shrink-0 rounded-l bg-gray-300 object-cover"
                                              src={'https://promogogo.com/cdn-cgi/image/width=400,height=225,fit=cover/' + event.image}
                                              alt=""/>)}
                        {!event.image && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300 "/>)}
                    </div>
                    <div className="min-w-0 flex-1 pt-2 pb-2 md:grid gap-4 grid-cols-3">
                        <div>
                            <span className="absolute inset-0" aria-hidden="true" data-button={'viewEvent'}
                                  data-eventid={event['@id']}/>
                            <p className="text-md font-bold text-gray-900">{event.name}</p>
                            {event.gogo && (<>
                                <p className={'text-sm font-medium text-gray-900'}>{event.gogo.majorType} </p>
                            </>)}
                            <p className="truncate text-sm text-gray-700">{event.location && event.location.name}</p>
                            <p className="truncate text-sm text-gray-500">{getLocalDateForEvent(event, event.localStartDate, event.timezone)}</p>
                        </div>

                        <div>
                            {artists && artists.map(artist => <div className="text-sm font-medium text-gray-900">
                                {artist.name}
                            </div>)}
                        </div>
                        <div>
                            <span className="isolate inline-flex rounded-md shadow-sm">
                    {event.gogo && event.gogo.ad === 'true' && (<>
                        <button
                                type="button"
                                className={adBtnGroupClasses}
                                data-button={'setAd'} data-key={'gjuggad'} data-value={'true'} data-unique={true}
                                data-remove={true} data-eventid={event['@id']}
                                title={'Remove ad'}

                        >
                            <i className="fa-duotone fa-billboard" data-button={'setAd'} data-key={'gjuggad'}
                               data-value={'true'} data-unique={true}
                               data-remove={true} data-eventid={event['@id']}></i>
                        </button>
                    </>)}{!event.gogo || !event.gogo.ad && (<>
                                <button
                                        type="button"
                                        className={adBtnGroupClasses}
                                        data-button={'setAd'} data-key={'gjuggad'} data-value={'true'}
                                        data-unique={true}
                                        data-remove={false} data-eventid={event['@id']}
                                        title={'Set as ad'}
                                >
                                    <i className="fa-duotone fa-billboard" data-button={'setAd'} data-key={'gjuggad'}
                                       data-value={'true'}
                                       data-unique={true}
                                       data-remove={false} data-eventid={event['@id']}></i>
                                </button>
                            </>)}

                                <button
                                        type="button"
                                        onClick={() => copyTextToClipboard('https://gjugg.is/event/gjugg/' + event['@id'])}
                                        className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                        title={'Copy link'}
                                >

        <i className="fa-solid fa-copy"></i>
      </button>
                                {event.admin && event.admin.isprivate && (<button
                                        type="button"
                                        className="relative -ml-px inline-flex items-center bg-red-700 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 hover:text-white focus:z-10"
                                        data-button={'setPrivate'}
                                        data-key={'setprivate'}
                                        data-value={'true'}
                                        data-unique={true}
                                        data-remove={false}
                                        data-eventid={event['@id']}
                                        title={'Make event public'}

                                ><i className="fa-duotone fa-eye-slash" data-button={'setPrivate'}
                                    data-key={'setprivate'} data-value={'true'}
                                    data-unique={true}
                                    data-remove={false} data-eventid={event['@id']}></i>
                                </button>)}

                                {event.admin && !event.admin.isprivate && (<button
                                        type="button"
                                        className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                        data-button={'setPrivate'} data-key={'setprivate'} data-value={'false'}
                                        data-unique={false}
                                        data-remove={false} data-eventid={event['@id']}
                                        title={'Make event private'}

                                ><i className="fa-duotone fa-eye" data-button={'setPrivate'} data-key={'setprivate'}
                                    data-value={'false'}
                                    data-unique={true}
                                    data-remove={true} data-eventid={event['@id']}></i>


                                </button>)}
                                {!event.admin && (<button
                                        type="button"
                                        className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                        data-button={'setPrivate'} data-key={'setprivate'} data-value={'false'}
                                        data-unique={false}
                                        data-remove={false}
                                        data-eventid={event['@id']}
                                        title={'Make event private'}

                                ><i className="fa-duotone fa-eye" data-button={'setPrivate'} data-key={'setprivate'}
                                    data-value={'false'}
                                    data-unique={true}
                                    data-remove={false} data-eventid={event['@id']}></i>


                                </button>)}

                                <button
                                        type="button"
                                        onClick={() => window.open(`${process.env.REACT_APP_API_URL}/go/promogogo/createmoment.do?event=` + event['@id'], '_blank')}
                                        className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                        title={'Edit event'}
                                >
        <i className="fa-duotone fa-pen-to-square"></i>
      </button>




    </span>
                            <div className={'mt-2'}>
                                <span className="isolate inline-flex rounded-md shadow-sm">
                                {event.gogo && event.gogo.majorType && event.gogo.majorType === 'music' && (<>
                                    <button
                                            type="button"
                                            className={'relative -ml-px rounded inline-flex items-center bg-red-700 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-green-400 hover:text-white focus:z-10'}
                                            data-button={'setAttractionType'} data-key={'attractionType'}
                                            data-value={'community'}
                                            data-unique={true}
                                            data-remove={false} data-eventid={event['@id']}
                                            title={'Set as community event'}

                                    >
                                        <i className="fa-solid fa-people-arrows" data-button={'setAttractionType'}
                                           data-key={'attractionType'}
                                           data-value={'community'}
                                           data-unique={true}
                                           data-remove={false} data-eventid={event['@id']}></i>
                                    </button>
                                </>)}{event.gogo && event.gogo.majorType && event.gogo.majorType !== 'music' && (<>
                                    <button
                                            type="button"
                                            className={'relative -ml-px rounded inline-flex items-center bg-green-700 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 hover:text-white focus:z-10'}
                                            data-button={'setAttractionType'} data-key={'attractionType'}
                                            data-value={'music'}
                                            data-unique={true}
                                            data-remove={false} data-eventid={event['@id']}
                                            title={'Set as music event'}

                                    >
                                        <i className="fa-duotone fa-music" data-button={'setAttractionType'}
                                           data-key={'attractionType'}
                                           data-value={'music'}
                                           data-unique={true}
                                           data-remove={false} data-eventid={event['@id']}></i>
                                    </button>
                                </>)}
                                    {!event.gogo || !event.gogo.majorType && (<>
                                        <button
                                                type="button"
                                                className={'relative -ml-px rounded inline-flex items-center bg-green-700 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 hover:text-white focus:z-10'}
                                                data-button={'setAttractionType'} data-key={'attractionType'}
                                                data-value={'music'}
                                                data-unique={true}
                                                data-remove={false} data-eventid={event['@id']}
                                                title={'Set as music event'}

                                        >
                                            <i className="fa-duotone fa-music" data-button={'setAttractionType'}
                                               data-key={'attractionType'}
                                               data-value={'music'}
                                               data-unique={true}
                                               data-remove={false} data-eventid={event['@id']}></i>
                                        </button>
                                    </>)}
                                </span>
                            </div>

                        </div>
                    </div>
                </div>

                <div className={'mt-2 mb-2'}>

                <span className="hidden md:block">
                 {audienceButtons(event)}



    </span>
                </div>

                {/*{artists && artists.map(artist => <>{smallArtistCard(artist)}</>)}
                {event.location && smallVenueCard(event.location)}*/}
                {/*
                {event.description && (<div className="text-sm p-3" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(event.description)}}></div>)}
*/}

            </>

    )
}

export const adminActionCard = (event, counter) => {
    var artists = [];
    if (event.performers)
    {
        artists = event.performers;
        //console.log('adminEventCard',artists)

    }
    var adBtnGroupClasses = classNames('relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10', {
        'bg-green-500': event.gogo && event.gogo.ad,
        'text-white': event.gogo && event.gogo.ad,
        'bg-white': !event.gogo || !event.gogo.ad,
        'text-gray-900': !event.gogo || !event.gogo.ad,
    });

    return (<>
                <div
                        key={event.guid + "-" + counter}
                        className="relative flex space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-pg_green"

                >
                    <div className="flex-shrink-0 flex items-center">
                        {hasAttribute(event, "venueID") && (
                                <div className="h-10 w-10 flex-shrink-0 rounded-full bg-blue-900 "/>)}
                        {hasAttribute(event, "artistID") && (
                                <div className="h-10 w-10 flex-shrink-0 rounded-full bg-green-800 "/>)}
                    </div>
                    <div className="min-w-0 flex-1 pt-2 pb-2 md:grid gap-4 grid-cols-3">
                        <div>
                            <span className="absolute inset-0" aria-hidden="true" data-button={'viewArtistOrVenue'}
                                  data-artistid={getAttribute(event, 'artistID')}
                                  data-venueid={getAttribute(event, 'venueID')}/>
                            <p className="text-sm font-bold text-gray-900 mb-2">{event.title}</p>
                            {event.gogo && (<>
                                <p className={'text-sm font-medium text-gray-900'}>{event.gogo.majorType} </p>
                            </>)}
                            <p className="truncate text-sm text-gray-700">{event.location && event.location.name}</p>
                            <p className="truncate text-sm text-gray-500"></p>
                            <span className={'isolate inline-flex rounded-md shadow-sm'}>
                            <button className={'relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'}
                                    data-button={'viewArtistOrVenue'}
                                    data-artistid={getAttribute(event, 'artistID')}
                                    data-venueid={getAttribute(event, 'venueID')}>View
                            </button>
                            <button className={'relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'}
                                    data-button={'resolveActionItem'}
                                    data-actionid={event.guid}>Resolve
                            </button>
                                </span>
                        </div>

                        <div>
                            {artists && artists.map(artist => <div className="text-sm font-medium text-gray-900">
                                {artist.name}
                            </div>)}
                        </div>
                        <div>
                            <span className="isolate inline-flex rounded-md shadow-sm">




    </span>
                            <div className={'mt-2'}>
                                <span className="isolate inline-flex rounded-md shadow-sm">
                                {event.gogo && event.gogo.majorType && event.gogo.majorType === 'music' && (<>
                                    <button
                                            type="button"
                                            className={'relative -ml-px rounded inline-flex items-center bg-red-700 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-green-400 hover:text-white focus:z-10'}
                                            data-button={'setAttractionType'} data-key={'attractionType'}
                                            data-value={'community'}
                                            data-unique={true}
                                            data-remove={false} data-eventid={event['@id']}
                                            title={'Set as community event'}

                                    >
                                        <i className="fa-solid fa-people-arrows" data-button={'setAttractionType'}
                                           data-key={'attractionType'}
                                           data-value={'community'}
                                           data-unique={true}
                                           data-remove={false} data-eventid={event['@id']}></i>
                                    </button>
                                </>)}{event.gogo && event.gogo.majorType && event.gogo.majorType !== 'music' && (<>
                                    <button
                                            type="button"
                                            className={'relative -ml-px rounded inline-flex items-center bg-green-700 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 hover:text-white focus:z-10'}
                                            data-button={'setAttractionType'} data-key={'attractionType'}
                                            data-value={'music'}
                                            data-unique={true}
                                            data-remove={false} data-eventid={event['@id']}
                                            title={'Set as music event'}

                                    >
                                        <i className="fa-duotone fa-music" data-button={'setAttractionType'}
                                           data-key={'attractionType'}
                                           data-value={'music'}
                                           data-unique={true}
                                           data-remove={false} data-eventid={event['@id']}></i>
                                    </button>
                                </>)}
                                    {!event.gogo || !event.gogo.majorType && (<>
                                        <button
                                                type="button"
                                                className={'relative -ml-px rounded inline-flex items-center bg-green-700 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-red-400 hover:text-white focus:z-10'}
                                                data-button={'setAttractionType'} data-key={'attractionType'}
                                                data-value={'music'}
                                                data-unique={true}
                                                data-remove={false} data-eventid={event['@id']}
                                                title={'Set as music event'}

                                        >
                                            <i className="fa-duotone fa-music" data-button={'setAttractionType'}
                                               data-key={'attractionType'}
                                               data-value={'music'}
                                               data-unique={true}
                                               data-remove={false} data-eventid={event['@id']}></i>
                                        </button>
                                    </>)}
                                </span>
                            </div>

                        </div>
                    </div>
                </div>

                <div className={'mt-2 mb-2'}>

                <span className="hidden md:block">
                 {audienceButtons(event)}



    </span>
                </div>

                {/*{artists && artists.map(artist => <>{smallArtistCard(artist)}</>)}
                {event.location && smallVenueCard(event.location)}*/}
                {/*
                {event.description && (<div className="text-sm p-3" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(event.description)}}></div>)}
*/}

            </>

    )
}

export const adminEventCard = (event) => {
    var artists = [];
    if (event.performers)
    {
        artists = event.performers;
        //console.log('adminEventCard',artists)

    }
    var adBtnGroupClasses = classNames('relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10', {
        'bg-green-500': event.gogo && event.gogo.ad,
        'text-white': event.gogo && event.gogo.ad,
        'bg-white': !event.gogo || !event.gogo.ad,
        'text-gray-900': !event.gogo || !event.gogo.ad,
    });

    return (<>
                <div
                        key={event.eventID}
                        className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-pg_green"

                >
                    <div className="flex-shrink-0 w-1/4">
                        {event.image && (<img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300 object-cover"
                                              src={'https://promogogo.com/cdn-cgi/image/width=230,fit=pad/' + event.image}
                                              alt=""/>)}
                        {!event.image && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300 "/>)}
                    </div>
                    <div className="min-w-0 flex-1 pt-2 pb-2">
                        <a href={'/event/' + event['@id']} className="focus:outline-none" target={'_blank'}>
                            <span className="absolute inset-0" aria-hidden="true"/>
                            <p className="text-sm font-medium text-gray-900">{event.name}</p>
                            <p className="truncate text-sm text-gray-700">{event.location && event.location.name}</p>
                            <p className="truncate text-sm text-gray-500">{getLocalDate(event.localStartDate, event.timezone)}</p>
                        </a>
                        <div>

                        </div>
                    </div>
                </div>
                <div className={'mt-2 mb-2'}>
                <span className="isolate inline-flex rounded-md shadow-sm">
                    {event.gogo && event.gogo.ad === 'true' && (<>
                        <button
                                type="button"
                                className={adBtnGroupClasses}
                                data-button={'setAd'} data-key={'gjuggad'} data-value={'true'} data-unique={true}
                                data-remove={true} data-eventid={event['@id']}

                        >
                            Ad
                        </button>
                    </>)}{!event.gogo || !event.gogo.ad && (<>
                    <button
                            type="button"
                            className={adBtnGroupClasses}
                            data-button={'setAd'} data-key={'gjuggad'} data-value={'true'} data-unique={true}
                            data-remove={false} data-eventid={event['@id']}

                    >
                        Set ad
                    </button>
                </>)}

                    <button
                            type="button"
                            onClick={() => copyTextToClipboard('https://gjugg.is/event/gjugg/' + event['@id'])}
                            className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                    >
        Copy
      </button>
      <button
              type="button"
              onClick={() => window.open(`${process.env.REACT_APP_API_URL}/go/promogogo/createmoment.do?event=` + event['@id'], '_blank')}
              className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      >
        Edit
      </button>
    </span>
                </div>

                {audienceButtons(event)}

                {artists && artists.map(artist => <>{smallArtistCard(artist)}</>)}
                {event.location && smallVenueCard(event.location)}
                {event.description && (<div className="text-sm p-3"
                                            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(event.description)}}></div>)}

            </>

    )
}

export const isUrlValid = (string) => {
    try
    {
        new URL(string);
        return true;
    }
    catch (err)
    {
        return false;
    }
}

export const getSite = (string) => {
    try
    {
        const url = new URL(string);
        return url.hostname;
    }
    catch (err)
    {
        return false;
    }
}

export const smallSchemaVenueCard = (venue) => {
    return (<div
                    key={venue.locationID}
                    className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
            >
                <div className="flex-shrink-0">
                    {venue.image && (
                            <img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300" src={venue.image} alt=""/>)}
                    {!venue.image && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300"/>)}
                </div>
                <div className="min-w-0 flex-1 pt-2 pb-2">
                    <a href="#" className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true"/>
                        <p className="text-sm font-medium text-gray-900">{venue.name}</p>
                        <p className="truncate text-sm text-gray-500">{venue.location && venue.location.city}</p>
                    </a>
                </div>
            </div>

    )
}

export const adminVenueCard = (venue) => {
    const editurl = `/venue/` + venue['@id'];
    return (<>
        <div
                key={venue.locationID}
                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm  hover:border-gray-400 mb-3"
        >
            <div className="flex-shrink-0">
                {venue.image && (<img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300" src={venue.image}
                                      alt=""/>)}
            </div>
            <div className="min-w-0 flex-1 pt-2 pb-2">
                <a href={editurl} target={'_blank'} className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true"/>
                    <p className="text-md font-medium text-gray-900">{venue.name}</p>
                    <p className="truncate text-sm text-gray-500">Street: {venue.address && venue.address.streetAddress} </p>
                    <p className="truncate text-sm text-gray-500">City: {venue.address && venue.address.addressLocality}</p>
                    <p className="truncate text-sm text-gray-500">Region: {venue.address && venue.address.addressRegion}</p>
                    <p className="truncate text-sm text-gray-500">Country: {venue.address && venue.address.addressCountry}</p>

                </a>
            </div>
            <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true"/>

        </div>
        <div id={'map'}></div>

    </>)
}

export const smallSchemaArtistCard = (artist) => {
    return (<div
                    key={artist.artistID}
                    className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
            >
                <div className="flex-shrink-0">
                    {artist.image && (
                            <img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300" src={artist.image} alt=""/>)}
                    {!artist.image && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300"/>)}
                </div>
                <div className="min-w-0 flex-1 pt-2 pb-2">
                    <a href={'/'+urlfriendly(artist.name)+"/artist/" + artist['@id']} className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true"/>
                        <p className="text-sm font-medium text-gray-900">{artist.name}</p>
                        <p className="truncate text-sm text-gray-500">{artist.gogo && artist.gogo.majorType}</p>
                    </a>
                    <p className={'text-sm'} dangerouslySetInnerHTML={{__html: artist.description}}></p>

                </div>
            </div>

    )
}

export const relatedSchemaArtistCard = (artist) => {
    return (<>
        <div
                onClick={() => window.location.href = '/'+urlfriendly(artist.name)+"/artist/" + artist['@id']}
                key={artist.artistID}
                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
        >
            <div className="flex-shrink-0">
                {artist.image && (
                        <img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300" src={artist.image} alt=""/>)}
                {!artist.image && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300"/>)}
            </div>
            <div className="min-w-0 flex-1 pt-2 pb-2">
                <p className="text-sm font-medium text-gray-900">{artist.name}</p>
                <p className="truncate text-sm text-gray-500">{artist.gogo && artist.gogo.majorType}{artist.address && artist.address.addressCountry &&
                                                                                                     <span> {artist.address.addressCountry}</span>}</p>
                {artist.description && <>
                    <Truncate
                            lines={2}
                            ellipsis={<span>
          ...
        </span>}
                            className={'text-sm text-gray-500'}

                    >
                        {artist.description.replace(/<[^>]*>?/gm, '')}
                    </Truncate>
                </>}


            </div>

        </div>
    </>)
}

export const sidebarArtistCard = (artist) => {
    return (<>
        <div
                key={artist.artistID}
                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
        >
            <div className="flex-shrink-0">
                {artist.image && (<a
                        href={artist.image}
                        download={artist.name + " image"}
                        target="_blank"
                        rel="noreferrer"
                >
                    <img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300" src={artist.image}
                         alt=""/>
                    <div className={'text-sm text-gray-500'}>Click to download</div>
                </a>)}
                {!artist.image && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300"/>)}
            </div>
            <div className="min-w-0 flex-1 pt-2 pb-2">
                <a href={'/attraction/' + artist['@id']} className="focus:outline-none" target={'_blank'}>
                    <p className="text-sm font-medium text-gray-900">{artist.name}</p>
                    <p className="truncate text-sm text-gray-500">{artist.gogo && artist.gogo.majorType}</p>
                </a>


            </div>
        </div>
        <p className={'text-sm p-2'} dangerouslySetInnerHTML={{__html: artist.description}}></p>
    </>)
}

export const adminArtistCard = (artist) => {
    return (<div
                    key={artist.artistID}
                    className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
            >
                <div className="flex-shrink-0">
                    {artist.image && (
                            <img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300" src={artist.image} alt=""/>)}
                    {!artist.image && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300"/>)}
                </div>
                <div className="min-w-0 flex-1 pt-2 pb-2">
                    <a href="#" className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" data-button={'editPerformer'}
                              data-performerid={artist['@id']}/>
                        <p className="text-sm font-medium text-gray-900">{artist.name}</p>
                        <p className="truncate text-sm text-gray-500">{artist.gogo && artist.gogo.majorType}</p>
                    </a>
                    <p className={'text-sm'}>{artist.description}</p>

                </div>
            </div>

    )
}

export const smallVenueCard = (venue) => {
    console.log('smallVenueCard', venue)
    return (<>
        <div
                key={venue['@id']}
                className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
        >
            <div className="flex-shrink-0">
                {venue.image && (<img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300" src={venue.image}
                                      alt=""/>)}
                {!venue.image && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300"/>)}
            </div>
            <div className="min-w-0 flex-1 pt-2 pb-2">
                <a href={'/venue/' + venue['@id']} className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true"/>
                    <p className="text-sm font-medium text-gray-900">{venue.name}</p>
                    <p className="truncate text-sm text-gray-500">{venue.address && venue.address.streetAddress && venue.address.streetAddress + ", "}{venue.address && venue.address.addressLocality && venue.address.addressLocality}</p>
                </a>
            </div>
        </div>
    </>)
}

export const isIDInArray = (array, value) => {
    //console.log('isInArray',array,value)
    return array.find((element) => {
        return element['@id'] === value;
    })
}

export const isIndexInArray = (array, value) => {
    //console.log('isInArray',array,value)
    return array.find((element) => {
        return element.index === value;
    })
}

export const smallArtistCard = (artist) => {
    //console.log('smallArtistCard',artist)
    return (<div
                    key={artist['@id']}
                    className="mt-2 relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                    onClick={() => window.open('/attraction/' + artist['@id'], '_blank')}

            >
                <div className="flex-shrink-0">
                    {artist.image && (
                            <img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300" src={artist.image} alt=""/>)}
                    {!artist.image && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300"/>)}
                </div>
                <div className="min-w-0 flex-1 pt-2 pb-2">
                    <a href="#" className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true"/>
                        <p className="text-sm font-medium text-gray-900">{artist.name}</p>
                        <p className="truncate text-sm text-gray-500"></p>
                    </a>
                </div>
            </div>

    )
}

export const workingWithCard = (artist) => {
    return (<div
                    key={artist['@id']} id={'attraction' + artist['@id']}
                    className="mt-2 relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                    onClick={() => window.location.href = `/`+urlfriendly(artist.name)+`/artist/` + artist['@id']}

            >
                <div className="flex-shrink-0">
                    {artist.image && (
                            <img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300" src={artist.image} alt=""/>)}
                    {!artist.image && (<div className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300"/>)}
                </div>
                <div className="min-w-0 flex-1 pt-2 pb-2">
                    <a href="#" className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" data-button={'clickedAttraction'}
                              data-attractionid={artist['@id']}/>
                        <p className="text-sm font-medium text-gray-900">{artist.name}</p>
                        <p className="truncate text-sm text-gray-500">{artist.gogo && artist.gogo.artistType && artist.gogo.artistType}{artist.gogo && artist.gogo.artistType && <> - </>}{artist.address && artist.address.addressCountry && artist.address.addressCountry}</p>
                    </a>
                </div>
            </div>

    )
}

export const workingWithCardPlusEdit = (artist) => {
    return (<>
        <div>
            <div
                    key={artist['@id']} id={'attraction' + artist['@id']}
                    className="mt-2 relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                    /*onClick={() => window.open(`${process.env.REACT_APP_API_URL}/go/adminartists.do#!/artist/` + artist['@id'], '_blank')}*/

            >

                <div className="flex-shrink-0">
                    {artist.image && (<img className="h-20 w-30 flex-shrink-0 rounded-l bg-gray-300" src={artist.image}
                                           data-button={'clickedAttraction'}
                                           data-attractionid={artist['@id']} alt=""/>)}
                    {!artist.image && (<div data-button={'clickedAttraction'}
                                            data-attractionid={artist['@id']}
                                            className="h-10 w-16 flex-shrink-0 rounded-full bg-gray-300"/>)}
                </div>
                <div className="min-w-0 flex-1 pt-2 pb-2">
                    <div className="focus:outline-none cursor-pointer" data-button={'clickedAttraction'}
                         data-attractionid={artist['@id']}>
                        <p className="text-sm font-medium text-gray-900" data-button={'clickedAttraction'}
                           data-attractionid={artist['@id']}>{artist.name}</p>
                        <p className="truncate text-sm text-gray-500" data-button={'clickedAttraction'}
                           data-attractionid={artist['@id']}>{artist.gogo && artist.gogo.artistType && artist.gogo.artistType}{artist.gogo && artist.gogo.artistType && <> - </>}{artist.address && artist.address.addressCountry && artist.address.addressCountry}</p>
                    </div>


                </div>


            </div>
            <div className={'mt-1 isolate inline-flex rounded-md shadow-sm'}>
                <button
                        className={'relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'}
                        data-button={'copyDescription'} data-lang={'is'} data-artistid={artist['@id']}
                >IS

                </button>

                <button data-button={'copyDescription'} data-lang={'en'} data-artistid={artist['@id']}
                        className={'relative -ml-px inline-flex items-center  bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'}>
                    EN
                </button>
                <button data-button={'copyDescription'} data-lang={'default'} data-artistid={artist['@id']}
                        className={'relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'}>
                    Default
                </button>
                <button data-button={'inheritAudience'} data-lang={'default'} data-inheritartist={artist['@id']}
                        className={'relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'}>
                    Copy Audiences
                </button>
            </div>
        </div>

    </>)
}

export const toastSuccess = (message) => {
    toast(message);
}



