import React, {Fragment, useEffect, useRef, useState} from 'react'
import axios, * as others from 'axios';
import {useLoaderData, useLocation, useParams} from "react-router-dom";
import {
    addEventAttribute,
    audienceButtons,
    changeAttribute,
    getAttribute,
    getBestImage,
    getImageWithPrefix,
    getLocalDate,
    hasAttribute,
    imagePad,
    inheritAudiences,
    manageAttractionData,
    MetaTags, overlayCard,
    relatedSchemaArtistCard,
    setAttribute,
    setRoomAttribute,
    setUniqueAttractionAttribute,
    setUniqueEventAttribute,
    smallEventCard,
    smallSchemaArtistCard,
    spotifyPlayer,
    thumbnailPad,
    toastSuccess
} from "../../components/common/utilities";
import {Dialog, Transition} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {ToastContainer} from "react-toastify";
import {Footer, Header} from '../../components/common/headerAndFooter';

function classNames(...classes: any[])
{
    return classes.filter(Boolean).join(' ')
}

export default function ViewArtist() {
    let params = useParams();
    const venueID = params.venueID;
    const data = useLoaderData() as any;
    //console.log(data);

    type SchemaAttraction = {
        [key: string]: any; // ?? variable key
        name: string;
    };

    const text = useRef("");
    const inFocus = useRef("");
    const [attractionData, setAttractionData] = useState<SchemaAttraction>();
    const [events, setEvents] = useState([]);
    const [relatedArtists, setRelatedArtists] = useState([]);
    const [photos, setPhotos] = useState([]);
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffffff");
    const [open, setOpen] = useState(false);
    const [showAI, setShowAI] = useState(false);
    const [showMediaSelector, setShowMediaSelector] = useState(false);
    const [attractionName, setAttractionName] = useState("");

    useEffect(() => {
        setAttractionData(data);
        getEvents("");
        getRelatedArtists();

    }, []);
    const now = new Date();


    const getRelatedArtists = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/p/dynamic/relatedartists.do`, {

            tiny: true, artist: params.artistID, admin: true
        }, {
                       headers: {
                           'Content-Type': 'application/x-www-form-urlencoded'
                       }

                   }).then(function (response) {
            // handle success
            //console.log(response.data);
            setRelatedArtists(response.data.collection)
        }).catch(function (error) {
            // handle error
            console.log(error);
        });
    }

    function clickedEvent(e: any)
    {

    }



    const getEvents = (nextOffset: any) => {
        axios.get(`${process.env.REACT_APP_API_URL}/p/events/upcoming.do`, {
            params: {
                instance: 'promogogo',
                limit: 12, offset: nextOffset, artist: params.artistID, light: true,
            }

        }).then(function (response) {
            // handle success
            //console.log("events", response);
            setEvents(response.data.collection);

        });
    }


    return (

        <div className="min-h-full">
            {attractionData && <MetaTags
                    title={attractionData.name + ' | Gjugg - allir viðburðir'}
                    description={attractionData.description}
                    image={attractionData.image && getImageWithPrefix(attractionData.image)} />
            }

            <Header/>
            <div className="md:p-2 ">

                <main>
                    {attractionData &&
                     <div className="grid sm:grid-cols-3 gap-4">
                         <div className="col-span-2">
                             <div className="mt-2">
                                 <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">

                                     <div className="p-1 md:px-4 py-5 sm:p-1">
                                         {attractionData && <div className=" md:px-2 sm:mt-16 sm:px-0 lg:mt-0">
                                             {overlayCard(attractionData)}

                                             <div>
                                                 {attractionData && attractionData.gogo && attractionData.gogo.artistType && attractionData.gogo.artistType}
                                             </div>

                                             {attractionData && attractionData.address && attractionData.address.addressCountry &&
                                              <div className={'mt-1 text-sm text-gray-500 p-2'}>from {attractionData.address.addressCountry}</div>}


                                             {attractionData.description && (<>
                                                 <p className={'mt-2 p-2'}
                                                    dangerouslySetInnerHTML={{__html: attractionData.description}}></p>
                                             </>)}

                                         </div>}
                                         <div className=" sm:mt-16 sm:px-0 lg:mt-0">
                                             <section aria-labelledby="details-heading" className="mt-12">
                                                 {events && events.length > 0 &&
                                                  <h3 className={'text-lg font-bold tracking-tight text-gray-900'}>Viðburðir</h3>}
                                                 <div>

                                                     <div
                                                             className="grid grid-cols-1 md:grid-cols-2 gap-4 xl:grid-cols-2">
                                                         {events.map((event, index) => (<div key={event['@id']}>
                                                             {smallEventCard(event)}
                                                         </div>))}

                                                     </div>
                                                 </div>
                                             </section>

                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                         <div className="col-span-1">
                             {attractionData && attractionData.gogo && attractionData.gogo.spotify &&
                              <div className={'mt-2'}>{spotifyPlayer(attractionData)}</div>}
                             {relatedArtists && relatedArtists.length > 0 && <>
                                 <h5 className={'text-lg font-bold tracking-tight text-white mt-2'}>Related Artists</h5>
                                 <div className="mt-2"

                                 >
                                     {relatedArtists.map((artist, index) => (<div key={artist['@id']} className={'mt-2'}>
                                         {relatedSchemaArtistCard(artist)}
                                     </div>))}
                                 </div>

                             </>}
                         </div>
                     </div>
                    }
                </main>

            </div>
            <Footer/>

            <ToastContainer autoClose={800} theme={'dark'}/>

        </div>)
}
