import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Disclosure, Menu, Transition} from '@headlessui/react'
import {Bars3Icon, BellIcon, XMarkIcon, ListBulletIcon, HomeIcon} from '@heroicons/react/24/outline'

import {useLoaderData, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {
	addEventAttribute,
	adminEventCard,
	audienceButtons, MetaTags,
	setUniqueEventAttribute,
	smallEventCard,
	smallSchemaArtistCard,
	toastSuccess
} from "../../components/common/utilities";
import {ChevronDownIcon, MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {ArrowLongLeftIcon, ArrowLongRightIcon, ChevronDoubleLeftIcon} from "@heroicons/react/16/solid";
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import {Footer, Header} from "../../components/common/headerAndFooter";

function classNames(...classes: any[]) {
	return classes.filter(Boolean).join(' ')
}

export default function Admarketiceland() {
	const navigate = useNavigate();
	const logout = () => {
		window.location.href = '/logout';
	}



	type SchemaArtist = {
		[key: string]: any; // ?? variable key
		name: string;
	};
const [artistData, setArtistData] = useState<SchemaArtist>();

	var date = new Date();
	const stopBouncing = () => {
		const el = document.getElementById("promo-icon");
		if (el) {
			el.classList.remove("animate-bounce");
		}
	}
	useEffect(() => {
		setTimeout(() => stopBouncing(), 3500)
		//getPhotos();

	}, []);

	const pages = [

		{ name: 'Fyrir Fjölmiðla', href: '/forthemedia', current: false },
		{ name: 'Menningarumfjöllun', href: '/documentation/mediaiceland', current: false },
		{ name: 'Auglýsingar', href: '/documentation/admarketiceland', current: true },
	]




	return (<>
		<MetaTags
			title="Auglýstu innanlands"
			description={'Alveg jafn auðveldlega og á Facebook, Google og Instagram. Aurinn hættir að streyma úr landi og þú nærð betur til þeirra sem þú vilt.'}
			image="https://promogogo.com/cdn-cgi/image/fit=contain,width=400/https://media.promogogo.com/ymsirvidburdir/2024-08-26/ymsirvidburdir-3dd43861-6d71-4d23-8a76-2e20c565fcb0-formedia.png"
		/>
		<Header/>

		<div
			className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">

			<div
				className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
				<div
					className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">

					<div className="lg:pr-4">
						<div className="lg:max-w-lg">
							<p className="text-base font-semibold leading-7 text-indigo-600">Meiri athygli - fleiri
								gestir</p>
							<h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Auglýstu
								innanlands</h1>
							<p className="mt-6 text-xl leading-8 text-gray-700">
								Alveg jafn auðveldlega og á Facebook, Google og Instagram. Aurinn hættir að streyma úr
								landi og þú nærð betur til þeirra sem þú vilt.
							</p>
						</div>
					</div>
				</div>
				<div
					className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
					<img
						className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
						src="https://promogogo.com/cdn-cgi/image/fit=contain,width=1280/https://media.promogogo.com/ymsirvidburdir/2024-06-04/6aba3cfaa2864afbbafe428cc5147b73.png"
						alt=""
					/>
				</div>
				<div
					className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
					<div className="lg:pr-4">
						<div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
							<p>
								Það er svo auðvelt að "boosta" viðburði á Facebook og Instagram. Þú getur gert
								það sama hér. Þú velur bara viðburðinn, velur hópana sem þú vilt ná til og
								hversu miklu þú vilt eyða til þess.
							</p>
							<ul role="list" className="mt-8 space-y-8 text-gray-600">

								<li className="flex gap-x-3">
									<ListBulletIcon className="mt-1 h-5 w-5 flex-none text-indigo-600"
													aria-hidden="true"/>
									<span>
                    <strong className="font-semibold text-gray-900">Þú velur viðburðinn.</strong> Þeir eru á skrá hjá okkur með mynd og öllu.
                  </span>
								</li>

								<li className="flex gap-x-3">
									<ListBulletIcon className="mt-1 h-5 w-5 flex-none text-indigo-600"
													aria-hidden="true"/>
									<span>
                    <strong className="font-semibold text-gray-900">Gervigreindin </strong> stingur upp á markhópum fyrir viðburðinn.
                  </span>
								</li>

								<li className="flex gap-x-3">
									<ListBulletIcon className="mt-1 h-5 w-5 flex-none text-indigo-600"
													aria-hidden="true"/>
									<span>
                    <strong className="font-semibold text-gray-900">Þú velur upphæðina</strong> og við birtum auglýsingu um viðburðinn á þeim miðlum sem henta markhópnum.
                  </span>
								</li>
							</ul>
							<p className="mt-8">
								Þetta þýðir að þú getur nú birt viðburðaauglýsingar á innlendum miðlum á jafn
								einfaldan hátt og hjá erlendum stórbokkum.
							</p>
							<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Og meira
								til</h2>
							<p className="mt-6">
								Þetta styrkir innlenda fjölmiðla í sessi - sem og íslenskuna.
							</p>
						</div>


						<div className="mt-10 flex items-center gap-x-6">
							<a
								href="/forthemedia"
								className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								Miðlar
							</a>

							<a
								href="/media"
								className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								Umfjöllun
							</a>
							<a
								href="/admarket"
								className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								Tekjur
							</a>

						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer/>



	</>)
}
